import { ReactNode, FC, CSSProperties } from 'react';
import { theme } from 'antd';

interface ContentProps {
  children?: ReactNode;
  transparent?: boolean;
  margin?: boolean;
  style?: CSSProperties;
  fullScreenWidth?: boolean;
}

const Content: FC<ContentProps> = ({
  children,
  margin,
  transparent,
  fullScreenWidth,
  style,
}) => {
  const {
    token: { colorBgContainer, borderRadius, boxShadowTertiary },
  } = theme.useToken();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: fullScreenWidth ? '100%' : 'calc(100% - 48px)',
        marginTop: margin ? '24px' : '',
        padding: '10px 10px',
        backgroundColor: transparent ? 'transparent' : colorBgContainer,
        borderRadius: borderRadius,
        boxShadow: boxShadowTertiary,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export { Content };
