import { Spin } from "antd";

const Loading = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin
        tip="Carregando..."
        size="large"
        style={{ justifySelf: "center" }}
      />
    </div>
  );
};

export { Loading };
