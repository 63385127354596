/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row } from 'antd';

interface ExpandableFilterProps {
  fields: JSX.Element[];
  form: FormInstance<any>;
  onFinish?: (values: any) => void;
  onClear?: () => void;
}

const ExpandableFilter: FC<ExpandableFilterProps> = ({
  fields,
  form,
  onFinish,
  onClear,
}) => {
  const [expand, setExpand] = useState(false);

  const getFields = () => {
    const count = expand ? 7 : 3;
    const children = [];
    for (let i = 0; i < count; i++) {
      children.push(fields[i]);
    }
    return children;
  };

  return (
    <Form
      form={form}
      name="expandable_filter_search"
      onFinish={onFinish}
      style={{ padding: '14px 40px' }}
    >
      <Row align={'middle'}>
        <Col span={20}>
          <Row gutter={24} align={'middle'}>
            {getFields()}
          </Row>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Button
            onClick={() => {
              form.resetFields();
              if (onClear) {
                onClear();
              }
            }}
          >
            Limpar
          </Button>
          <Button type="primary" htmlType="submit">
            Pesquisar
          </Button>
          {fields.length > 3 ? (
            <a
              onClick={() => {
                setExpand(!expand);
              }}
              style={{ padding: 0 }}
            >
              {expand ? <UpOutlined /> : <DownOutlined />}{' '}
              {expand ? 'Voltar' : 'Expandir'}
            </a>
          ) : null}
        </Col>
      </Row>
    </Form>
  );
};

export { ExpandableFilter };
