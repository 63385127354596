import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';

interface EditPasswordModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

export default function EditPasswordModal({
  isModalOpen,
  onClose,
}: EditPasswordModalProps) {
  const [loading] = useState(false);
  const [form] = Form.useForm();

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    // form.resetFields();
    // setFiles([]);
    // setValues(undefined);
    onClose();
  };

  return (
    <Modal
      title={'Alterar Senha'}
      open={isModalOpen}
      onCancel={handleCloseModal}
      width={540}
      centered
      footer={
        <div
          style={{
            display: 'flex',
            gap: 10,
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handleCloseModal} style={{ width: 100 }}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={() => {}}
            style={{ margin: 0, width: 100 }}
          >
            Alterar
          </Button>
        </div>
      }
    >
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <Form
          form={form}
          layout="vertical"
          name="edit_profile"
          // onFinish={next}
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20,
          }}
        >
          <Form.Item
            label="Senha Antiga:"
            name="old_password"
            rules={[
              {
                required: true,
                message: 'Por favor, informe sua Senha Antiga!',
              },
            ]}
            colon
            style={{ display: 'block' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Nova Senha:"
            name="new_password"
            rules={[
              {
                required: true,
                message: 'Por favor, informe uma Nova Senha!',
              },
            ]}
            colon
            style={{ display: 'block' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Confirmar Senha:"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: 'Por favor, confirme a Nova Senha!',
              },
            ]}
            colon
            style={{ display: 'block' }}
          >
            <Input />
          </Form.Item>
        </Form>
      </section>
    </Modal>
  );
}
