import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Modal,
  Typography,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { TOKEN_API, api } from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';

interface EditProfileModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

export default function EditProfileModal({
  isModalOpen,
  onClose,
}: EditProfileModalProps) {
  const [loading] = useState(false);
  const [file, setFile] = useState<any>('');
  const [form] = Form.useForm();
  const { user } = useAuth();

  const { Text } = Typography;

  useEffect(() => {
    console.log(user);
    form.setFields([
      { name: 'email', value: user?.account?.email },
      { name: 'name', value: user?.name },
    ]);
    // setFile(user?.account.image);
  }, [form, user]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    // form.resetFields();
    // setFiles([]);
    // setValues(undefined);
    onClose();
  };

  const onSubmit = () => {
    let data: any;
    file
      ? (data = {
          fullName: form.getFieldValue('name'),
          image: file[0].url && file[0].url,
        })
      : (data = {
          fullName: form.getFieldValue('name'),
          name: form.getFieldValue('name'),
        });
    console.log(data);
    api
      .put('/users', data)
      .then((data) => {
        console.log(data.data);
        console.log('deu tudo certo');
      })
      .catch((err) => console.log(err));
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: process.env.REACT_APP_API + `upload`,
    // action: `http://localhost:3333/upload`,
    data: {
      folder: 'user_info',
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      if (info.file.status === 'done') {
        setFile(info.fileList.map((file) => file.response));
        // console.log(info.fileList.map((file) => file.response));
        console.log(info);
        message.success(`${info.file.name} Imagem enviada com sucesso`);
      } else if (info.file.status === 'error') {
        console.log('deu erro');
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar a imagem.`);
        }
      }
    },
    accept: 'image/*',
    listType: 'picture',
    maxCount: 1,
    onRemove: () => setFile(null),
  };

  return (
    <Modal
      title={'Editar Usuário'}
      open={isModalOpen}
      onCancel={handleCloseModal}
      width={540}
      centered
      footer={
        <div
          style={{
            display: 'flex',
            gap: 10,
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handleCloseModal} style={{ width: 100 }}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={onSubmit}
            style={{ margin: 0, width: 100 }}
          >
            Salvar
          </Button>
        </div>
      }
    >
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <Text style={{ fontWeight: 500, marginTop: 20 }}>Informações</Text>
        <Form
          form={form}
          layout="vertical"
          name="edit_profile"
          // onFinish={next}
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
          }}
        >
          <Form.Item
            label="Nome:"
            name="name"
            rules={[
              {
                required: true,
                message: 'Por favor, informe um Nome!',
              },
            ]}
            colon
            style={{ display: 'block' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email:"
            name="email"
            rules={[
              {
                required: true,
                message: 'Por favor, informe um Email!',
              },
            ]}
            colon
            style={{ display: 'block' }}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Carregar Foto:"
            name="image"
            rules={[
              {
                required: true,
                message: 'Por favor, Selcione uma foto!',
              },
            ]}
            colon
            style={{ display: 'block' }}
          >
            <Upload {...props}>
              {!file && <Button icon={<UploadOutlined />}>Upload</Button>}
            </Upload>
          </Form.Item>
        </Form>
      </section>
    </Modal>
  );
}
