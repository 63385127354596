import { RouteObject } from 'react-router-dom';
import { Login } from './login';
import { CreateAccount } from './createAccount';
import { loader } from '../../utils/loader';

export const AuthRouter: RouteObject = {
  children: [
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/create-account',
      loader: loader,
      element: <CreateAccount />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
