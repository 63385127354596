import React from 'react';
import { Content } from '../../../components/Content';
import { Typography } from 'antd';
import { Pie, measureTextWidth, PieConfig } from '@ant-design/plots';

interface OSPerTypesChartProps {
  title: string;
  data: any;
}

export default function OSPerTypesChart({ title, data }: OSPerTypesChartProps) {
  const { Text } = Typography;

  const uniqueData: any = data.filter((item: any, index: any, self: any) => {
    return (
      index ===
      self.findIndex((t: any) => t.type === item.type && t.value === item.value)
    );
  }); //função responsavel por remover possiveis dados duplicados

  function renderStatistic(containerWidth: any, text: any, style: any) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };">${text}</div>`;
  }

  const config = {
    appendPadding: 10,
    data: uniqueData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.84,
    meta: {
      value: {
        formatter: (v: any) => `${v} ¥`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    legend: {
      //   title: 'ohmagó',
      //   custom: true,
      //   items: [],
      //   label: 'ol',
      //   itemValue: {
      //     // formatter(text, item, index) {
      //     //   console.log(item);
      //     // },
      //     style: {
      //       fontSize: 14,
      //     },
      //   },
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container: any, view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : 'Qtde de OS';
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '32px',
        },
        customHtml: (container: any, view: any, datum: any, data: any) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${datum.value}`
            : `${data.reduce((r: any, d: any) => r + d.value, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  } as PieConfig;

  return (
    <section style={{ flex: 1 }}>
      <Content fullScreenWidth style={{ marginTop: 10, padding: 24 }}>
        <Text>{title}</Text>
      </Content>
      <Content fullScreenWidth style={{ marginTop: 3, padding: 24 }}>
        <Pie /* style={{ width: '50%' }} */ {...config} />
      </Content>
    </section>
  );
}
