import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { Button, DatePicker, Space, Typography } from 'antd';
import { Content } from '../../components/Content';
import ServiceOrdersCards from './components/ServiceOrdersCards';
import OSPerLocalsChart from './components/OSPerLocalsChart';
import OSPerTypesChart from './components/OSPerTypesChart';
import { api } from '../../services/api';

export interface OSPerOptionsDataProps {
  type: string;
  value: number;
}

export default function Dashboard() {
  const [totalOS, setTotalOS] = useState<OSPerOptionsDataProps[]>(
    [] as OSPerOptionsDataProps[]
  );
  const [osPerLocalData, setOsPerLocalData] = useState<OSPerOptionsDataProps[]>(
    [] as OSPerOptionsDataProps[]
  );
  const [osPerServiceTypeData, setOsPerServiceTypeData] = useState<
    OSPerOptionsDataProps[]
  >([] as OSPerOptionsDataProps[]);
  const [osPerEquipmentTypeData, setOsPerEquipmentTypeData] = useState<
    OSPerOptionsDataProps[]
  >([] as OSPerOptionsDataProps[]);

  useEffect(() => {
    api
      .get('dashboard/')
      .then((data: any) => {
        setTotalOS(data.data);
        // const dataOBJ = data.data;
        // for (const type in dataOBJ) {
        //   const value = parseInt(dataOBJ[type]);
        //   setTotalOS((prevState) => {
        //     return [...prevState, { type, value }];
        //   });
        // }
      })
      .catch((err) => console.log(err));
    api
      .get('dashboard/locals')
      .then((data: any) => {
        const dataOBJ = data.data;
        for (const type in dataOBJ) {
          if (type !== 'total') {
            const value = parseInt(dataOBJ[type]);
            setOsPerLocalData((prevState) => {
              return [...prevState, { type, value }];
            });
          }
        }
      })
      .catch((err) => console.log(err));
    api
      .get('dashboard/service-types')
      .then((data: any) => {
        const dataOBJ = data.data;
        for (const type in dataOBJ) {
          if (type !== 'total') {
            const value = parseInt(dataOBJ[type]);
            setOsPerServiceTypeData((prevState) => {
              return [...prevState, { type, value }];
            });
          }
        }
      })
      .catch((err) => console.log(err));
    api
      .get('dashboard/equipment-types')
      .then((data: any) => {
        const dataOBJ = data.data;
        for (const type in dataOBJ) {
          if (type !== 'total') {
            const value = parseInt(dataOBJ[type]);
            setOsPerEquipmentTypeData((prevState) => {
              return [...prevState, { type, value }];
            });
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <PageHeader title="Dashboard" backButton={false} />
      <Content style={{ padding: '12px 24px', marginTop: 10 }}>
        <Space style={{ justifyContent: 'space-between' }}>
          <Typography.Text color="#262626">
            Filtrar Informações da Dashboard
          </Typography.Text>
          <Space>
            <Button type="text">Diário</Button>
            <Button type="text">Semanal</Button>
            <Button type="text">Mensal</Button>
            <Button type="text">Anual</Button>
            <DatePicker.RangePicker />
          </Space>
        </Space>
      </Content>
      <ServiceOrdersCards data={totalOS} />
      <Content style={{ padding: '12px 24px', marginTop: 16 }}>
        <Typography.Text>Nº de OS por Local</Typography.Text>
      </Content>
      <OSPerLocalsChart data={osPerLocalData} />
      <section
        style={{
          display: 'flex',
          width: 'calc(100% - 48px)',
          gap: 10,
        }}
      >
        <OSPerTypesChart
          title="Nº de OS por Tipo de Serviço"
          data={osPerServiceTypeData}
        />
        <OSPerTypesChart
          title="Nº de OS por Tipo de Equipamento"
          data={osPerEquipmentTypeData}
        />
      </section>
    </>
  );
}
