import { useState, useEffect, useCallback, FC } from 'react';

import { App, Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  CheckCircleOutlined,
  FileFilled,
  FileTextOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { IMeta, IPagination, IServiceOrder } from '../../../types';
import { Content } from '../../../components/Content';
import { PageHeader } from '../../../components/PageHeader';
import { api } from '../../../services/api';
import { ActionColumn, ActionFunction } from '../../../components/ActionColumn';
import { ServiceOrderStatusTag } from '../components/ServiceOrderStatusTag';
import { formatters } from '../../../utils/formatters';
import { FilterOrderList } from '../components/FilterOrderList';
import { SteperModal } from '../components/SteperModal';
import { useAuth } from '../../../contexts/AuthContext';

const getServiceOrders = async (page = 1, perPage = 10) => {
  return await api.get<IPagination<IServiceOrder[]>>(`/service-orders`, {
    // params: {
    //   page,
    //   perPage,
    // },
  });
};

const Orders: FC = () => {
  const { message } = App.useApp();
  const [orders, setOrders] = useState<IServiceOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const { user } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getServiceOrders(page, pageSize)
        .then(({ data }) => {
          setOrders(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            message.error(
              'Desculpe! no momento você não tem permissão para isso.'
            );
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [message]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IServiceOrder> = (record) => {
    const options = [
      {
        key: '1',
        icon: <SearchOutlined />,
        label: 'Detalhar',
        condition: true,
      },
      {
        key: '2',
        icon: <CheckCircleOutlined />,
        label: 'Aprovar OS',
        condition: user?.profile === 'MANAGER',
        // condition: record.users.find((user) => user.profile === 'MANAGER'),
      },
      {
        key: '3',
        icon: <FileFilled />,
        label: 'Espelho da OS',
        condition: user?.profile === 'MANAGER',
        // condition: record.users.find((user) => user.profile === 'MANAGER'),
      },
      {
        key: '4',
        icon: <FileTextOutlined />,
        label: 'Relatório de Atendimento',
        condition: user?.profile === 'MANAGER',
        // condition: record.users.find((user) => user.profile === 'MANAGER'),
      },
    ];

    const filter = [];

    for (let option of options) {
      if (option.condition) {
        filter.push(option);
      }
    }

    return filter;
  };

  const columns: ColumnsType<IServiceOrder> = [
    {
      title: 'ID',
      dataIndex: 'organizationCode',
      key: 'organizationCode',
      width: 80,
      render: (value) => (
        <Typography style={{ color: '#4ECB73' }}>
          {value?.toString().padStart(4, '0')}
        </Typography>
      ),
    },
    {
      title: 'Nº de Ref',
      dataIndex: 'referenceNumber',
      key: 'referenceNumber',
      width: 120,
      render: (value) => (
        <Typography style={{ color: '#4ECB73' }}>{value || '--'}</Typography>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text,
      ellipsis: true,
    },
    {
      title: 'Resp. Ciente',
      dataIndex: '',
      key: 'respclient',
      render: (text) => text,
      ellipsis: true,
    },
    {
      title: 'Local',
      dataIndex: 'local',
      key: 'local',
      render: (local) => local.name,
    },
    {
      title: 'Técnico',
      dataIndex: 'users',
      key: 'users',
      render: (users: IServiceOrder['users']) => {
        return users.find((user) => user.profile === 'TECHNICIAN')?.name;
      },
      ellipsis: true,
    },
    {
      title: 'Aberto em:',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => formatters.simpleDate(date),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (status) => <ServiceOrderStatusTag status={status} />,
    },
    ActionColumn(actions),
  ];

  return (
    <>
      <PageHeader
        title="Ordem de Serviço"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            key={'create_item'}
            onClick={handleShowModal}
            type="primary"
          >
            Solicitar Nova OS
          </Button>,
        ]}
      />
      <SteperModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onRefreshPage={() => setLoad(!load)}
      />
      <Content margin>
        <FilterOrderList />
      </Content>
      <Content margin>
        <Table
          pagination={{
            position: ['bottomRight'],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
            size: 'default',
          }}
          size="small"
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={orders}
        />
      </Content>
    </>
  );
};

export { Orders };
