import { Avatar, Button, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';

interface ProfileModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onPressConfirmButton: () => void;
  onPressEditPasswordButton: () => void;
}

export default function ProfileModal({
  isModalOpen,
  onClose,
  onPressConfirmButton,
  onPressEditPasswordButton,
}: ProfileModalProps) {
  const [loading] = useState(false);
  // const [userData, setUserData] = useState<IUser>({} as IUser);

  const { user } = useAuth();
  const { Text } = Typography;

  // console.log(user);

  // useEffect(() => {
  //   api
  //     .get('/users')
  //     .then((data) => console.log(data.data))
  //     .catch((err) => console.log(err));
  // }, []);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    // form.resetFields();
    // setFiles([]);
    // setValues(undefined);
    onClose();
  };

  return (
    <Modal
      title={'Usuário'}
      open={isModalOpen}
      onCancel={handleCloseModal}
      width={540}
      centered
      footer={
        <div
          style={{
            display: 'flex',
            gap: 10,
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={handleCloseModal} style={{ width: 100 }}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={onPressConfirmButton}
            style={{ margin: 0, width: 100 }}
          >
            Editar
          </Button>
        </div>
      }
    >
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <Avatar
          size={83}
          src={user?.account?.image}
          style={{ marginTop: 20 }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
            marginTop: 10,
          }}
        >
          <Text>Nome:</Text>
          <Text style={{ fontSize: 12, marginLeft: 12 }}>{user?.name}</Text>
          <Text>Email:</Text>
          <Text style={{ fontSize: 12, marginLeft: 12 }}>
            {user?.account?.email}
          </Text>
          <Text>Cargo:</Text>
          <Text style={{ fontSize: 12, marginLeft: 12 }}>
            {user?.profile === 'MANAGER' ? 'Administrador' : 'Cliente'}
          </Text>
        </div>
        <Button
          type="text"
          style={{ color: '#4ECB73', marginTop: 10, width: 120 }}
          onClick={onPressEditPasswordButton}
        >
          Alterar Senha
        </Button>
      </section>
    </Modal>
  );
}
