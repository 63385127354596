import { RouteObject } from 'react-router-dom';
import { Orders } from './list';
import { loader } from '../../utils/loader';

export const OrderRouter: RouteObject = {
  path: '/orders',
  loader: loader,
  element: <Orders />,
  errorElement: <h1>Route not found!</h1>,
};
