import React from 'react';

export default function DocumentUpload() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0001 28.3333V18.3333L11.6667 21.6666"
        stroke="#4ECB73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 18.3333L18.3333 21.6666"
        stroke="#4ECB73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.6666 16.6666V24.9999C36.6666 33.3333 33.3333 36.6666 24.9999 36.6666H14.9999C6.66659 36.6666 3.33325 33.3333 3.33325 24.9999V14.9999C3.33325 6.66659 6.66659 3.33325 14.9999 3.33325H23.3333"
        stroke="#4ECB73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.6666 16.6666H29.9999C24.9999 16.6666 23.3333 14.9999 23.3333 9.99992V3.33325L36.6666 16.6666Z"
        stroke="#4ECB73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
