import { RouteObject } from 'react-router-dom';
import { loader } from '../../utils/loader';
import Dashboard from '.';

export const DashboardRouter: RouteObject = {
  path: '/dashboard',
  loader: loader,
  element: <Dashboard />,
  errorElement: <h1>Route not found!</h1>,
};
