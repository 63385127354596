import { FC, useState } from 'react';
import { ConfigProvider, theme, App, FloatButton } from 'antd';

import ptBR from 'antd/locale/pt_BR';

import { Routes } from './routes';

const MyApp: FC = () => {
  const [selectedTheme /* setSelectedTheme */] = useState('light');

  // const handleChangeColorMode = () => {
  //   selectedTheme === 'light'
  //     ? setSelectedTheme('dark')
  //     : setSelectedTheme('light');
  // };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4ECB73',
          borderRadius: 2,
          wireframe: false,
        },
        algorithm:
          selectedTheme === 'light'
            ? theme.defaultAlgorithm
            : theme.darkAlgorithm,
        components: {
          Pagination: {
            // itemActiveBg: '#4ECB73',
            // colorPrimary: '#fff',
          },
        },
      }}
      locale={ptBR}
    >
      <App>
        {/* <FloatButton.Group
          shape="circle"
          style={{ right: 24 }}
          trigger="click"
          icon={<SettingFilled />}
        > */}
        {/* <FloatButton
            icon={<FormatPainterOutlined />}
            onClick={handleChangeColorMode}
            tooltip={selectedTheme === 'light' ? 'Tema Escuro' : 'Tema Claro'}
            type={selectedTheme === 'light' ? 'default' : 'primary'}
          /> */}
        <FloatButton.BackTop visibilityHeight={0} tooltip="Voltar ao Topo" />
        {/* </FloatButton.Group> */}
        <Routes />
      </App>
    </ConfigProvider>
  );
};

export { MyApp };
