import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Typography, theme } from 'antd';
import React from 'react';

interface OSCardProps {
  title: string;
  quantity: number;
  color: string;
  info: string;
}

interface ServiceOrdersCardsProps {
  data: any;
}

const OSCard = ({ title, quantity, color, info }: OSCardProps) => {
  const { Text } = Typography;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        borderBottom: `4px solid ${color}`,
        backgroundColor: colorBgContainer,
        padding: 24,
        borderRadius: 2,
      }}
    >
      <span
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Text style={{ fontSize: 12 }}>{title}</Text>
        <Tooltip placement="topRight" title={info}>
          <InfoCircleOutlined style={{ color: '#4ECB73' }} />
        </Tooltip>
      </span>
      <Text style={{ fontSize: 30, fontWeight: 500 }}>{quantity}</Text>
    </div>
  );
};

export default function ServiceOrdersCards({ data }: ServiceOrdersCardsProps) {
  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 22,
        width: 'calc(100% - 48px)',
        marginTop: 10,
      }}
    >
      <OSCard
        title="Total de Contratos"
        quantity={data.total}
        color="#141414"
        info="Informação sobre total de contratos"
      />
      <OSCard
        title="OS Totais"
        quantity={data.total}
        color="#3030DA"
        info="Informação sobre OS Totais"
      />
      <OSCard
        title="OS Em Aberto"
        quantity={data.OPEN}
        color="#4ECB73"
        info="Informação sobre OS Em Aberto"
      />
      <OSCard
        title="OS Em Execução"
        quantity={data.EXECUTION}
        color="#FAAD14"
        info="Informação sobre OS Em Execução"
      />
      <OSCard
        title="OS Em Atraso"
        quantity={data.DELAYED}
        color="#EB2F96"
        info="Informação sobre OS Em Atraso"
      />
      <OSCard
        title="OS Finalizadas"
        quantity={data.FINALIZED}
        color="#B27FFC"
        info="Informação sobre OS Finalizadas"
      />
      <OSCard
        title="OS Validadas"
        quantity={data.VALIDATED}
        color="#00BCFF"
        info="Informação sobre OS Validadas"
      />
    </section>
  );
}
