import { Content } from '../../../components/Content';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Typography } from 'antd';
import { OSPerOptionsDataProps } from '..';

interface OSPerLocalsChartProps {
  data: OSPerOptionsDataProps[];
}

export default function OSPerLocalsChart({ data }: OSPerLocalsChartProps) {
  const { Text } = Typography;

  // const dataTest = [
  //   {
  //     name: 'Hospital Santa Rosa',
  //     quantity: 38,
  //   },
  //   {
  //     name: 'Hospital São Mateus',
  //     quantity: 52,
  //   },
  //   {
  //     name: 'Hospital Teresinha',
  //     quantity: 61,
  //   },
  //   {
  //     name: 'Hospital Amecor',
  //     quantity: 145,
  //   },
  //   {
  //     name: 'Hospital Femina',
  //     quantity: 112,
  //   },
  //   {
  //     name: 'Shopping Pantanal',
  //     quantity: 215,
  //   },
  //   {
  //     name: 'Shopping Estação',
  //     quantity: 82,
  //   },
  //   {
  //     name: 'Shopping Goiabeiras',
  //     quantity: 15,
  //   },
  //   {
  //     name: 'Shopping Três Américas',
  //     quantity: 47,
  //   },
  //   {
  //     name: 'Shopping VG',
  //     quantity: 63,
  //   },
  //   {
  //     name: 'Colégio Adventista',
  //     quantity: 90,
  //   },
  //   {
  //     name: 'Escola Raimundo Pinheiro',
  //     quantity: 25,
  //   },
  // ];

  const uniqueData = data.filter((item, index, self) => {
    return (
      index ===
      self.findIndex((t) => t.type === item.type && t.value === item.value)
    );
  }); //função responsavel por remover possiveis dados duplicados

  const config = {
    data: uniqueData,
    xField: 'type',
    yField: 'value',
    color: '#4ECB73',
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        // style: {
        //   fill: '#4ECB73',
        // },
        formatter(text, item, index) {
          return index + 1;
        },
      },
    },
    meta: {
      type: {
        alias: 'Nome',
      },
      value: {
        alias: 'Atendimentos',
      },
    },
  } as ColumnConfig;

  interface rankingPositionProps {
    placing: number;
    name: string;
    quantity: number;
  }

  const RankingPosition = ({
    placing,
    name,
    quantity,
  }: rankingPositionProps) => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: placing < 4 ? '#4ECB73' : '#8C8C8C',
            width: 20,
            height: 20,
            borderRadius: 10,
          }}
        >
          <Text style={{ fontSize: 12, color: '#fff' }}>{placing}</Text>
        </span>
        <Text>{name}</Text>
      </div>
      <Text>{quantity}</Text>
    </div>
  );

  return (
    <Content style={{ marginTop: 10, padding: 24 }}>
      <section style={{ display: 'flex', gap: 40 }}>
        <Column
          {...config}
          width={window.innerWidth / 1.8044} // divisão responsavel para quando o tamanho da tela for 1366px(como no protótipo), o gráfico ficar com 757px de largura(como no protótipo), e aumentar de acordo com o tamanho da tela, mantendo a proporção
          height={300}
          style={{ marginTop: 30 }}
        />
        <section style={{ width: '100%' }}>
          <Text>Ranking por Número</Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100% - 44px)',
              marginTop: 20,
              gap: 22,
            }}
          >
            {uniqueData
              .sort((a, b) => b.value - a.value)
              .slice(0, 7)
              .map((item, i) => (
                <RankingPosition
                  name={item.type}
                  quantity={item.value}
                  placing={i + 1}
                />
              ))}
          </div>
        </section>
      </section>
    </Content>
  );
}
