import { FC } from 'react';
import { Col, Form, Input, Select } from 'antd';
import { ExpandableFilter } from '../../../components/ExpandableFilter';

interface FilterOrderListProps {
  onSubmit?: any;
}

interface FilterItemFormInstance {
  id?: string;
  status?: string[];
  isConsumible?: boolean;
}

const Status = [
  { name: 'REQUESTED', label: 'REQUISITADO' },
  { name: 'PENDING', label: 'PENDENTE' },
  { name: 'APPROVED', label: 'APROVADO' },
  { name: 'OPEN', label: 'ABERTO' },
  { name: 'REOPENED', label: 'REABERTO' },
  { name: 'WAITING', label: 'AGUARDANDO' },
  { name: 'EXECUTION', label: 'EXECUÇÃO' },
  { name: 'DELAYED', label: 'ATRASADO' },
  { name: 'FINALIZED', label: 'FINALiZADO' },
  { name: 'VALIDATED', label: 'VALIDADO' },
];

const FilterOrderList: FC<FilterOrderListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<FilterItemFormInstance>();

  const fields = [
    <Col span={7} key={1}>
      <Form.Item name="id" label="N° da OS:" style={{ margin: 0 }}>
        <Input allowClear placeholder="Insira um ID" />
      </Form.Item>
    </Col>,
    <Col span={7} key={2}>
      <Form.Item name="status" label="Status da OS" style={{ margin: 0 }}>
        <Select
          placeholder="Escolha o status"
          allowClear
          showSearch
          optionLabelProp="label"
          optionFilterProp="label"
          mode="multiple"
          options={Status.map((status) => {
            return {
              value: status.name,
              label: status.label,
            };
          })}
        />
      </Form.Item>
    </Col>,
    <Col span={7} key={3}>
      <Form.Item name="period" label="Período" style={{ margin: 0 }}>
        <Select
          placeholder="Escolha o período"
          allowClear
          showSearch
          optionLabelProp="label"
          optionFilterProp="label"
          mode="multiple"
          options={Status.map((status) => {
            return {
              value: status.name,
              label: status.label,
            };
          })}
        />
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterItemFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values, status: values.status?.toString() });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterOrderList };
